var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('CCard',{staticClass:"h-100"},[_c('CCardBody',{staticClass:"technician-form"},[_c('div',{staticClass:"shop__header"},[(!_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("TypesOrderTitle.NewOrderType"))+" ")]):_vm._e(),(_vm.isUpdate)?_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.orderTypeForm.type)+" ")]):_vm._e()]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"custom"},[_c('div',{staticClass:"custom__input"},[_c('h5',[_vm._v("1. "+_vm._s(_vm.$t("TypesOrderTitle.Detail")))]),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"order_type","rules":("max:" + (_vm.ORDER_TYPE_ROLE.ORDER_TYPE) + "|requiredExport")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.type")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.orderTypeForm.type),expression:"orderTypeForm.type",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"type","type":"text","placeholder":_vm.$t('TypeFormFields.type'),"autocomplete":"type","maxlength":_vm.ORDER_TYPE_ROLE.ORDER_TYPE},domProps:{"value":(_vm.orderTypeForm.type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm, "type", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleResetCode),expression:"handleResetCode"}],staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"code",attrs:{"name":"ID","rules":("requiredExport|max:" + (_vm.ORDER_TYPE_ROLE.ID)),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.code")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.orderTypeForm.id),expression:"orderTypeForm.id",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":_vm.$t('TypeFormFields.code'),"disabled":_vm.isUpdate ? true : false,"autocomplete":"email","maxlength":_vm.ORDER_TYPE_ROLE.ID},domProps:{"value":(_vm.orderTypeForm.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm, "id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.needLibrary")))]),_c('div',{class:['col-md-10 col-12']},[_c('b-form-checkbox',{attrs:{"name":"checkbox"},model:{value:(_vm.orderTypeForm.needLibrary),callback:function ($$v) {_vm.$set(_vm.orderTypeForm, "needLibrary", $$v)},expression:"orderTypeForm.needLibrary"}})],1)])]),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"description",attrs:{"name":"description","rules":("requiredExport|max:" + (_vm.ORDER_TYPE_ROLE.DESCRIPTION)),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.description")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.orderTypeForm.description),expression:"orderTypeForm.description",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"description","type":"text","placeholder":_vm.$t('TypeFormFields.description'),"maxlength":_vm.ORDER_TYPE_ROLE.DESCRIPTION},domProps:{"value":(_vm.orderTypeForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"displayPrice",attrs:{"name":"price","rules":("requiredExport|valueEqualZero|max:" + (_vm.ORDER_TYPE_ROLE.PRICE)),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"displayPrice"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.displayPrice")))]),_c('div',{class:['col-md-3 col-3', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.orderTypeForm.price),expression:"orderTypeForm.price",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"displayPrice","type":"text","data-type":"currency","placeholder":_vm.$t('TypeFormFields.displayPrice'),"maxlength":_vm.ORDER_TYPE_ROLE.PRICE},domProps:{"value":(_vm.orderTypeForm.price)},on:{"keydown":function($event){return _vm.checkValidNumber($event)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm, "price", $event.target.value.trim())},_vm.formatCurrency],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])]),_c('span',{staticClass:"py-2"},[_vm._v("$")])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{ref:"displayPhoto",attrs:{"name":"photo","rules":{
										requiredExport: !_vm.orderTypeForm.avatarPath,
										fileType: true,
										fileUpload: true,
									},"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var classes = ref.classes;
									var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.displayPhoto")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('span',[_vm._v(_vm._s(_vm.$t("TypeFormFields.descriptionPhoto")))]),(!_vm.orderTypeForm.avatarPath)?_c('div',{staticClass:"upload__file",on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'pencil-alt']}})],1):_c('div',{staticClass:"thumbnail mt-2"},[_c('b-img',{attrs:{"width":"370","height":"280","src":_vm.orderTypeForm.avatarPath}}),_c('button',{staticClass:"px-4 btn btn-outline-custom edit-photo",attrs:{"type":"button"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_vm._v(" "+_vm._s(_vm.$t("Button.Upload"))+" ")])],1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"id":"file","type":"file","accept":_vm.getTypeImage},on:{"change":_vm.onFileChanged}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('h5',[_vm._v("2. "+_vm._s(_vm.$t("TypesOrderTitle.PricingTable")))]),_c('div',{staticClass:"form-group position-relativ mt-4"},[_c('ValidationProvider',{attrs:{"name":"order_unit_amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var classes = ref.classes;
									var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"unitAmount"}},[_vm._v(_vm._s(_vm.$t("TypeFormFields.unitAmount")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.orderTypeForm.unitAmount),expression:"orderTypeForm.unitAmount",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"unitAmount","type":"text","placeholder":_vm.$t('TypeFormFields.unitAmount'),"maxlength":_vm.ORDER_TYPE_ROLE.UNIT_AMOUNT},domProps:{"value":(_vm.orderTypeForm.unitAmount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm, "unitAmount", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"table-responsive-md"},[_c('table',{staticClass:"table table-bordered table-hover table-responsive table-fit"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,class:header.class,attrs:{"scope":"col"}},[_vm._v(_vm._s(header.title)+" "),(header.title === 'Custom')?_c('div',{staticClass:"text-value font-weight-normal"},[_vm._v(_vm._s(_vm.$t("TypeFieldTable.customDesciption")))]):_vm._e()])})],2)]),_c('tbody',_vm._l((_vm.PricingTable),function(item,index2){return _c('tr',{key:index2},[_c('td',{staticClass:"text-break text-center fit-id",attrs:{"scope":"row"}},[_vm._v(_vm._s(item.name))]),_vm._l((_vm.headers),function(value,index3){return _c('td',{key:index3,staticClass:"text-break text-center",attrs:{"scope":"row"}},[_c('ValidationProvider',{attrs:{"name":("price-" + index2 + "-" + index3),"rules":{
														requiredExport: index3 !== _vm.headers.length - 1,
														valueEqualZero: true,
													}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
													var classes = ref.classes;
													var errors = ref.errors;
return [_c('div',{class:[classes]},[(
																index2 === _vm.PricingTable.length - 3 &&
																index3 === _vm.headers.length - 1
															)?_c('div',{staticClass:"form-control time_delivery"},[_vm._v(" "+_vm._s(_vm.orderTypeForm.packageOrderType[value.title]["timeOfDelivery"])+" ")]):(
																index2 === _vm.PricingTable.length - 1 &&
																index3 === _vm.headers.length - 1
															)?_c('div'):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderTypeForm.packageOrderType[value.title][item.value]),expression:"orderTypeForm.packageOrderType[value.title][item.value]"}],class:['form-control form-control-custom'],attrs:{"id":((value.title) + "-" + (item.value)),"type":"text","disabled":index2 === _vm.PricingTable.length - 2 &&
																index3 === _vm.headers.length - 1,"maxlength":_vm.setMaxlength(item.value, value.title)},domProps:{"value":(_vm.orderTypeForm.packageOrderType[value.title][item.value])},on:{"keydown":function($event){index2 >= _vm.PricingTable.length - 2
																	? _vm.checkValidNumber($event)
																	: _vm.checkValidPhone($event)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderTypeForm.packageOrderType[value.title], item.value, $event.target.value)},function($event){return _vm.handleInputPrice()}],"keyup":function($event){return _vm.formatCurrencyTable(value.title, item.value, $event)},"blur":function($event){return _vm.formatCurrencyTable(value.title, item.value, $event)}}}),(errors.length)?_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1)})],2)}),0)])])])]),_c('CRow',{staticClass:"text-right"},[_c('CCol',[_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Button.Cancel"))+" ")]),_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Button.Save"))+" ")])])],1)],1)]}}])})],1)],1),(_vm.showErrorFileSize)?_c('CustomModal',{attrs:{"submit-text":_vm.$t('Button.OK'),"is-show-cancel":false},on:{"submit-modal":_vm.confirm},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.messageImgErr)+" ")]},proxy:true}],null,false,1418382132)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }