import { render, staticRenderFns } from "./TypeOrderForm.vue?vue&type=template&id=3af3873e&scoped=true&"
import script from "./TypeOrderForm.vue?vue&type=script&lang=js&"
export * from "./TypeOrderForm.vue?vue&type=script&lang=js&"
import style0 from "./TypeOrderForm.vue?vue&type=style&index=0&id=3af3873e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af3873e",
  null
  
)

export default component.exports