<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 v-if="!isUpdate" class="mb-3">
						{{ $t("TypesOrderTitle.NewOrderType") }}
					</h5>
					<h5 v-if="isUpdate" class="mb-3">
						{{ orderTypeForm.type }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<h5>1. {{ $t("TypesOrderTitle.Detail") }}</h5>
								<!-- order type  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_type"
										:rules="`max:${ORDER_TYPE_ROLE.ORDER_TYPE}|requiredExport`"
									>
										<div class="form-group required row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.type")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="type"
													v-model.trim="orderTypeForm.type"
													type="text"
													class="form-control"
													:placeholder="$t('TypeFormFields.type')"
													autocomplete="type"
													:maxlength="ORDER_TYPE_ROLE.ORDER_TYPE"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- ID  -->
								<div v-click-outside="handleResetCode" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="code"
										name="ID"
										:rules="`requiredExport|max:${ORDER_TYPE_ROLE.ID}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.code")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email"
													v-model.trim="orderTypeForm.id"
													type="text"
													class="form-control"
													:placeholder="$t('TypeFormFields.code')"
													:disabled="isUpdate ? true : false"
													autocomplete="email"
													:maxlength="ORDER_TYPE_ROLE.ID"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Need library  -->
								<div class="form-group position-relative">
									<div class="form-group row">
										<label for="email" class="col-md-2 col-12 col-form-label">{{
											$t("TypeFormFields.needLibrary")
										}}</label>
										<div :class="['col-md-10 col-12']">
											<b-form-checkbox v-model="orderTypeForm.needLibrary" name="checkbox" />
										</div>
									</div>
								</div>

								<!-- Desciption  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="description"
										name="description"
										:rules="`requiredExport|max:${ORDER_TYPE_ROLE.DESCRIPTION}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.description")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="description"
													v-model.trim="orderTypeForm.description"
													type="text"
													class="form-control"
													:placeholder="$t('TypeFormFields.description')"
													:maxlength="ORDER_TYPE_ROLE.DESCRIPTION"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Display price  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="displayPrice"
										name="price"
										:rules="`requiredExport|valueEqualZero|max:${ORDER_TYPE_ROLE.PRICE}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="displayPrice" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.displayPrice")
											}}</label>
											<div :class="['col-md-3 col-3', classes]">
												<input
													id="displayPrice"
													v-model.trim="orderTypeForm.price"
													type="text"
													class="form-control"
													data-type="currency"
													:placeholder="$t('TypeFormFields.displayPrice')"
													:maxlength="ORDER_TYPE_ROLE.PRICE"
													@keydown="checkValidNumber($event)"
													@input="formatCurrency"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
											<span class="py-2">$</span>
										</div>
									</ValidationProvider>
								</div>

								<!-- Display photo  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="displayPhoto"
										name="photo"
										:rules="{
											requiredExport: !orderTypeForm.avatarPath,
											fileType: true,
											fileUpload: true,
										}"
										mode="lazy"
									>
										<!-- rules="requiredExport|fileType|fileUpload" -->
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.displayPhoto")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<span>{{ $t("TypeFormFields.descriptionPhoto") }}</span>
												<div
													v-if="!orderTypeForm.avatarPath"
													class="upload__file"
													@click="$refs.fileInput.click()"
												>
													<font-awesome-icon :icon="['fas', 'pencil-alt']" />
												</div>
												<div v-else class="thumbnail mt-2">
													<b-img width="370" height="280" :src="orderTypeForm.avatarPath" />
													<button
														type="button"
														class="px-4 btn btn-outline-custom edit-photo"
														@click="$refs.fileInput.click()"
													>
														{{ $t("Button.Upload") }}
													</button>
												</div>
												<input
													id="file"
													ref="fileInput"
													type="file"
													style="display: none"
													:accept="getTypeImage"
													@change="onFileChanged"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<h5>2. {{ $t("TypesOrderTitle.PricingTable") }}</h5>
								<div class="form-group position-relativ mt-4">
									<ValidationProvider v-slot="{ classes, errors }" name="order_unit_amount">
										<div class="form-group row">
											<label for="unitAmount" class="col-md-2 col-12 col-form-label">{{
												$t("TypeFormFields.unitAmount")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="unitAmount"
													v-model.trim="orderTypeForm.unitAmount"
													type="text"
													class="form-control"
													:placeholder="$t('TypeFormFields.unitAmount')"
													:maxlength="ORDER_TYPE_ROLE.UNIT_AMOUNT"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<div class="table-responsive-md">
									<table class="table table-bordered table-hover table-responsive table-fit">
										<thead>
											<tr>
												<th scope="col"></th>
												<th
													v-for="(header, index) in headers"
													:key="index"
													scope="col"
													:class="header.class"
													>{{ header.title }}
													<div
														v-if="header.title === 'Custom'"
														class="text-value font-weight-normal"
														>{{ $t("TypeFieldTable.customDesciption") }}</div
													>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index2) in PricingTable" :key="index2">
												<td scope="row" class="text-break text-center fit-id">{{ item.name }}</td>
												<td
													v-for="(value, index3) in headers"
													:key="index3"
													scope="row"
													class="text-break text-center"
												>
													<ValidationProvider
														v-slot="{ classes, errors }"
														:name="`price-${index2}-${index3}`"
														:rules="{
															requiredExport: index3 !== headers.length - 1,
															valueEqualZero: true,
														}"
													>
														<div :class="[classes]">
															<div
																v-if="
																	index2 === PricingTable.length - 3 &&
																	index3 === headers.length - 1
																"
																class="form-control time_delivery"
															>
																{{ orderTypeForm.packageOrderType[value.title]["timeOfDelivery"] }}
															</div>
															<div
																v-else-if="
																	index2 === PricingTable.length - 1 &&
																	index3 === headers.length - 1
																"
															></div>
															<input
																v-else
																:id="`${value.title}-${item.value}`"
																v-model="orderTypeForm.packageOrderType[value.title][item.value]"
																type="text"
																:class="['form-control form-control-custom']"
																:disabled="
																	index2 === PricingTable.length - 2 &&
																	index3 === headers.length - 1
																"
																:maxlength="setMaxlength(item.value, value.title)"
																@keydown="
																	index2 >= PricingTable.length - 2
																		? checkValidNumber($event)
																		: checkValidPhone($event)
																"
																@input="handleInputPrice()"
																@keyup="formatCurrencyTable(value.title, item.value, $event)"
																@blur="formatCurrencyTable(value.title, item.value, $event)"
															/>
															<div v-if="errors.length" class="error-mess">
																{{ errors[0] }}
															</div>
														</div>
													</ValidationProvider>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ $t("Button.Save") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="showErrorFileSize"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
		>
			<template #content>
				{{ messageImgErr }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import ClickOutside from "vue-click-outside"
import { upperCase, capitalize, round, isInteger } from "lodash-es"
import { ORDER_TYPE_ROLE } from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import { SET } from "../store/mutation-types"
const { mapState, mapMutations } = createNamespacedHelpers("typeOrder")
export default {
	name: "TypeOrderForm",
	directives: {
		ClickOutside,
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ORDER_TYPE_ROLE,
			showErrorFileSize: false,
			messageImgErr: null,
			orderTypeForm: {
				type: null,
				id: null,
				needLibrary: false,
				description: null,
				price: null,
				unitAmount: null,
				packageOrderType: {
					Basic: {
						amount: null,
						timeOfDelivery: null,
						price: null,
						pricePerImage: null,
					},
					Standard: {
						amount: null,
						timeOfDelivery: null,
						price: null,
						pricePerImage: null,
					},
					Premium: {
						amount: null,
						timeOfDelivery: null,
						price: null,
						pricePerImage: null,
					},
					Custom: {
						amount: null,
						timeOfDelivery: null,
						price: null,
					},
				},
				avatarPath: null,
			},
			uploadFile: null,
			headers: [
				{
					title: this.$t("TypeFieldTable.basic"),
					class: "text-center",
				},
				{
					title: this.$t("TypeFieldTable.standard"),
					class: "text-center",
				},
				{
					title: this.$t("TypeFieldTable.premium"),
					class: "text-center",
				},
				{
					title: this.$t("TypeFieldTable.custom"),
					class: "text-center",
				},
			],
			PricingTable: [
				{
					name: this.$t("TypeFieldTable.amount"),
					value: "amount",
				},
				{
					name: this.$t("TypeFieldTable.timeDelivery"),
					value: "timeOfDelivery",
				},
				{
					name: this.$t("TypeFieldTable.price"),
					value: "price",
				},
				{
					name: this.$t("TypeFieldTable.pricePerImage"),
					value: "pricePerImage",
				},
			],
		}
	},
	computed: {
		...mapState(["typeOrderDetail", "messageErr"]),
		getTypeImage() {
			return ".jpg, .png, .jpeg"
		},
	},
	watch: {
		typeOrderDetail() {
			this.setUpData()
		},
		messageErr(val) {
			if (val) this.setFormFieldErr("code", val)
		},
		uploadFile(val) {
			if (val) {
				this.$refs.displayPhoto.reset()
			}
		},
	},
	created() {
		this.setUpData()
	},
	methods: {
		...mapMutations({ SET }),
		handleResetCode() {
			this.$refs.code.reset()
		},
		setMaxlength(type, packet) {
			if (packet === "Custom" && type === "price") return
			switch (type) {
				case "amount":
					return ORDER_TYPE_ROLE.PRICING.AMOUNT
				case "timeOfDelivery":
					return ORDER_TYPE_ROLE.PRICING.TIME_DELIVERY
				case "pricePerImage":
					return ORDER_TYPE_ROLE.PRICING.PER_IMAGE

				default:
					return ORDER_TYPE_ROLE.PRICING.PRICE
			}
		},
		submitForm: async function () {
			this.SET(["messageErr", null])
			let params = {
				type: this.orderTypeForm.type,
				id: this.orderTypeForm.id,
				needLibrary: this.orderTypeForm.needLibrary,
				description: this.orderTypeForm.description,
				price: this.setPrice(this.orderTypeForm.price),
				packageOrderType: this.getpackageOrderType(),
				file: this.uploadFile,
				unitAmount: this.orderTypeForm.unitAmount,
			}
			this.$emit("submit", params)
		},
		cancel: function () {
			this.$emit("cancel")
		},
		setUpData() {
			// for update orderTypeForm
			if (this.isUpdate && this.typeOrderDetail) {
				this.orderTypeForm = { ...this.typeOrderDetail }
				this.orderTypeForm.price = `${this.orderTypeForm.price}`
				this.orderTypeForm.packageOrderType = this.setpackageOrderType()
				this.formatCurrency()
			}
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors([err])
		},
		async onFileChanged(event) {
			const { valid, errors } = await this.$refs.displayPhoto.validate(event)
			if (!valid) {
				this.messageImgErr = errors[0]

				this.$refs.fileInput.value = ""
				this.$refs.displayPhoto.reset()
				this.$refs.displayPhoto.value = null
				this.openModal()
				return
			}

			this.uploadFile = event.target.files[0]

			this.orderTypeForm.avatarPath = URL.createObjectURL(this.uploadFile)
		},
		openModal() {
			this.showErrorFileSize = true
			this.$store.commit("set", ["modalShow", true])
		},
		confirm() {
			this.showErrorFileSize = false
			this.$store.commit("set", ["modalShow", false])
		},
		handleInputPrice() {
			// if (name === "Custom" && type === "amount") {
			let dataPrice = this.orderTypeForm.packageOrderType
			const premium = dataPrice["Premium"]
			const basic = dataPrice["Basic"]
			const standard = dataPrice["Standard"]
			const custom = dataPrice["Custom"]

			if (!custom.amount) {
				custom.timeOfDelivery = null
				return (custom.price = null)
			}

			if (
				!basic.amount ||
				!standard.amount ||
				!premium.amount ||
				!basic.timeOfDelivery ||
				!standard.timeOfDelivery ||
				!premium.timeOfDelivery
			) {
				return (custom.timeOfDelivery = null)
			}

			// set time of delivery

			const amount = Number(custom.amount)

			if (amount > Number(premium.amount)) {
				custom.timeOfDelivery = Math.ceil(
					(Number(premium.timeOfDelivery) / Number(premium.amount)) * amount,
				)
			} else if (
				(amount > Number(standard.amount) && amount <= Number(premium.amount)) ||
				(amount === Number(standard.amount) && amount === Number(premium.amount))
			) {
				custom.timeOfDelivery = Number(premium.timeOfDelivery)
			} else if (
				(amount > Number(basic.amount) && amount <= Number(standard.amount)) ||
				(amount === Number(basic.amount) && amount === Number(standard.amount))
			) {
				custom.timeOfDelivery = standard.timeOfDelivery
			} else {
				custom.timeOfDelivery = basic.timeOfDelivery
			}

			// set custom price with amout

			if (!basic.amount || !basic.price || !premium.amount || !premium.price) {
				return (custom.price = null)
			}

			if (
				parseFloat(basic.amount) === 0 ||
				parseFloat(basic.price) === 0 ||
				parseFloat(premium.amount) === 0 ||
				parseFloat(premium.price) === 0
			) {
				custom.timeOfDelivery = null
				return (custom.price = null)
			}

			if (parseFloat(custom.amount) >= parseFloat(premium.amount)) {
				custom.price =
					(this.convertCurrencyToString(premium.price) * parseFloat(custom.amount)) /
					parseFloat(premium.amount)
			} else {
				custom.price =
					(this.convertCurrencyToString(basic.price) * parseFloat(custom.amount)) /
					parseFloat(basic.amount)
			}

			if (isInteger(custom.price)) {
				custom.price = this.formatNumber(round(custom.price, 2).toString())
			} else {
				let decimal_pos = custom.price.toString().split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				right_side = right_side.substring(0, 2)
				custom.price = left_side + "." + right_side
			}
		},
		getpackageOrderType() {
			let array = []
			let dataPrice = { ...this.orderTypeForm.packageOrderType }
			delete dataPrice["Custom"]
			for (const property in dataPrice) {
				const object = {
					name: upperCase(property),
					amount: parseFloat(dataPrice[property].amount),
					price: parseFloat(this.convertCurrencyToString(dataPrice[property].price)),
					timeOfDelivery: parseFloat(dataPrice[property].timeOfDelivery),
					pricePerImage: Number(this.convertCurrencyToString(dataPrice[property].pricePerImage)),
				}
				array.push(object)
			}
			return array
		},
		setpackageOrderType() {
			const object = {}
			let dataPrice = [...this.orderTypeForm.packageOrderType]
			dataPrice.forEach(el => {
				object[capitalize(el.name)] = {
					...el,
					price: this.formartPrice(`${el.price}`),
					amount: `${el.amount}`,
					timeOfDelivery: `${el.timeOfDelivery}`,
					pricePerImage: this.formartPrice(`${Number(el.pricePerImage)}`),
				}
				delete object[capitalize(el.name)].name
			})
			object["Custom"] = {
				amount: null,
				timeOfDelivery: null,
				price: null,
			}
			return object
		},
		formartPrice(price) {
			if (price.includes(".")) {
				let decimal_pos = price.split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				return left_side + "." + right_side
			} else {
				return this.formatNumber(price)
			}
		},
		setPrice(price) {
			if (price.includes(".")) {
				return parseFloat(price)
			}
			return parseFloat(price.split(",").join(""))
		},
		formatNumber(n) {
			// format number 1000000 to 1,234,567
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		formatCurrency() {
			// appends $ to value, validates decimal side
			// and puts cursor back in right position.

			const input = document.querySelector("#displayPrice")

			// get input value
			let input_val = input.value || this.orderTypeForm.price

			// if (input_val.length > ORDER_TYPE_ROLE.PRICE) {
			// 	input_val = this.formatNumber(input_val.split("").slice(0, 4).join(""))
			// 	input.value = input_val
			// 	this.orderTypeForm.price = input_val
			// }

			// don't validate empty input
			if (input_val === "" || parseFloat(input_val) === 0) {
				return
			}

			// original length
			let original_len = input_val.length

			// initial caret position
			let caret_pos = input.selectionStart
			// check for decimal
			if (input_val.indexOf(".") < 0) {
				// no decimal entered
				// add commas to number
				// remove all non-digits
				input_val = this.formatNumber(input_val)
				input_val = input_val
			} else {
				let decimal_pos = input_val.split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				right_side = right_side.substring(0, 2)
				input_val = left_side + "." + right_side
			}

			if (input_val.length > ORDER_TYPE_ROLE.PRICE) {
				input_val = this.formatNumber(input_val.split("").slice(0, 5).join(""))
				input.value = input_val
				this.orderTypeForm.price = input_val
			}

			// send updated string to input
			input.value = input_val
			this.orderTypeForm.price = input_val

			// put caret back in the right position
			let updated_len = input_val.length
			caret_pos = updated_len - original_len + caret_pos
			input.setSelectionRange(caret_pos, caret_pos)
		},
		formatCurrencyTable(name, type, event) {
			const ignoreKeyCode = [17, 65, 86, 88, 67]
			if (ignoreKeyCode.includes(event.keyCode)) return

			// appends $ to value, validates decimal side
			// and puts cursor back in right position.
			const input = document.querySelector(`#${name}-${type}`)

			// get input value
			let input_val = input.value

			// don't validate empty input
			if (input_val === "" || parseFloat(input_val) === 0) {
				return
			}

			// original length
			let original_len = input_val.length

			// initial caret position
			let caret_pos = input.selectionStart
			// check for decimal
			if (input_val.indexOf(".") < 0) {
				// no decimal entered
				// add commas to number
				// remove all non-digits
				input_val = this.formatNumber(input_val)
				input_val = input_val
			} else {
				let decimal_pos = input_val.split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				right_side = right_side.substring(0, 2)
				input_val = left_side + "." + right_side
			}

			if (input_val.length > ORDER_TYPE_ROLE.PRICING.PRICE) {
				input_val = this.formatNumber(input_val.split("").slice(0, 6).join(""))
				input.value = input_val
				this.orderTypeForm.packageOrderType[name][type] = input_val
			}

			// send updated string to input
			input.value = input_val
			this.orderTypeForm.packageOrderType[name][type] = input_val

			// put caret back in the right position
			let updated_len = input_val.length
			caret_pos = updated_len - original_len + caret_pos
			input.setSelectionRange(caret_pos, caret_pos)
		},
		convertCurrencyToString(currency) {
			if (!currency) return
			return currency.split(",").join("")
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.custom__input {
	width: 100%;
	// margin: auto;
}
.upload__file {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	border: 1px solid $border-input;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 12px;
}
.error__note {
	color: $red;
}

.table {
	th {
		border-bottom: unset;
		width: 5%;
		vertical-align: middle;
	}

	td {
		vertical-align: middle;
	}
}
.table-responsive {
	border-right: unset;
}
.form-control-custom {
	margin: auto;
	text-align: center;

	&:disabled {
		background: $white !important;
	}
}
.time_delivery {
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	cursor: not-allowed;
}
.thumbnail {
	width: 370px;
	height: 280px;
	position: relative;
	clear: both;

	.edit-photo {
		transition: all 0.5s ease-in-out;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale3d(0, 0, 0);
		text-align: center;
		z-index: 1;
		color: $white;
		border-color: $yellow;
		background: $yellow;

		&:hover {
			color: $white;
			border-color: $yellow;
			background: $yellow;
		}
	}

	&::before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $black;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s;
	}

	&:hover {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.15s;
		transform: translateY(0px);

		&::before {
			visibility: visible;
			opacity: 0.3;
		}

		.edit-photo {
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 0.5s;
		}
	}
}
</style>
